@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-mode {
    background-color: black;
    color: white;
  }

  .custom.react-toggle--checked .react-toggle-track {
    background-color: rgb(66, 133, 244);
  }

  .custom.react-toggle--checked:hover:not(react-toggle--disabled) .react-toggle-track {
    background-color: rgb(66, 133, 244);
  }